import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "../Home/HomePage.css";
import { Card, Row, Col, Button, Container, Modal } from 'react-bootstrap';
import { Box, colors, Slider, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import "./ProductDetails.css"

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
// import { faTimes, faHeart } from '@fortawesome/free-solid-svg-icons';
import Sliding from 'react-slick';
import LoadingSpinner from '../Home/LoadingSpinner';
// import { MetaTags } from 'react-meta-tags';

const basePath = "http://api.index911.com/";


function valuetext(value) {
  return `${value} SEK`;
}


const Products = () => {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState([25, 100000]);
  const [optionList, setOptionList] = useState([{}]);
  const [country, setCountry] = useState([{}]);
  const [filteredList, setFilteredList] = useState([{}]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedItem, setCheckedItem] = useState([]);
  const [average, setAverage] = useState([{}]);
  const [active, Setactive] = useState(0);
  const [page, setPage] = useState(1);
  const [checkedAverage, setCheckedAverage] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [filteredData, setFilteredData] = useState([]);
  const [model_id, setModel_id] = useState('');
  const [country_id, setCountry_id] = useState('');
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [filteredCountry, setFilteredCountry] = useState([]);



  const currentUrl = location.pathname + location.search;
  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  function valuetext(value) {
    return `${value}°C`;
  }
  const fetchModels = () => {
    setIsLoading(true);
    fetch(`https://api.index911.com/api/cars/model`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'client-access-token': 'HS512384256562345690',
        },
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "Ok") {
          setOptionList(data.data);
          setFilteredList(data.data);
          setIsLoading(false);
        }
        else {
          toast.dismiss();
          toast.error(data.message)
        }
      });
  };

  const fetchCountry = () => {
    setIsLoading(true);
    fetch(`https://api.index911.com/api/cars/getAllCountries`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'client-access-token': 'HS512384256562345690',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "Ok") {
          setCountry(data.data);
          setFilteredCountry(data.data); // Initialize filteredCountry with all countries
          setIsLoading(false);
        } else {
          toast.dismiss();
          toast.error(data.message);
        }
      });
  };

  const fetchAverage = () => {
    setIsLoading(true);
    fetch(`https://api.index911.com/api/cars/average`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'client-access-token': 'HS512384256562345690',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "Ok") {
          const fetchedAverage = data.data;
          const averageWithConvertedPrice = fetchedAverage.map((price) => {
            const convertedPrice = price.average_price * price.currency_converter_price;
            return {
              ...price,
              converted_price: convertedPrice.toFixed(3),
            };
          });
          setAverage(averageWithConvertedPrice);
          console.log(data, "  console.log(data);  console.log(data);");

          setIsLoading(false);
        } else {
          toast.dismiss();
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchModels();
    fetchCountry();
    fetchAverage();
  }, []);

  const handlePreviousImage = (user) => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? user.images.length - 1 : prevIndex - 1
    );
  };
  const handleNextImage = (user) => {
    setCurrentImageIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      if (nextIndex === user.images.length) {
        setIsDialogOpen(false);
        return prevIndex;
      }
      if (nextIndex > user.images.length) {
        setIsDialogOpen(false);
        return prevIndex;
      }
      return nextIndex;
    });
  };
  const handleImageClick = (index, user) => {
    setCurrentImageIndex(index);
    setSelectedUser(user);
    setIsDialogOpen(true);
  };
  const handleChangeModels = (e) => {
    const selectedModel = e.target.name;
    const isChecked = e.target.checked;
    const selectedCount = Object.values(checkedItems).filter((item) => item === true).length;
    if (isChecked && selectedCount >= 4) {
      // If already four selections made, prevent selecting additional models
      e.preventDefault();
      return;
    }
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [selectedModel]: isChecked,
    }));
  };

  const handleChangeCountries = (ev) => {
    const selectedCountry = ev.target.name;
    const isChecked = ev.target.checked;
    const selectedCount = Object.values(checkedItem).filter((item) => item).length;

    // Check if the maximum number of selections (4) has been reached
    if (isChecked && selectedCount >= 4) {
      // If already four selections made, prevent selecting additional countries
      ev.preventDefault();
      return;
    }

    setCheckedItem((prevCheckedItem) => ({
      ...prevCheckedItem,
      [selectedCountry]: isChecked,
    }));
  };

  const handleChangeAverage = (en) => {
    setCheckedAverage({
      ...checkedAverage,
      [en.target.name]: en.target.checked
    });
  };

  const getDataOnFilterUpdate = () => {
    const selectedModels = Object.keys(checkedItems).filter((key) => checkedItems[key]);
    const selectedCountries = Object.keys(checkedItem).filter((key) => checkedItem[key]);
    const selectedAverages = Object.keys(checkedAverage).filter((key) => checkedAverage[key]);
    const model_ids = [];
    const country_ids = [];
    const averages = [];
    if (selectedModels.length > 0) {
      selectedModels.forEach((model) => {
        filteredList.forEach((allmodel) => {
          if (allmodel.model_name === model) {
            model_ids.push({ model_id: allmodel.id });
          }
        });
      });
    }
    if (selectedCountries.length > 0) {
      selectedCountries.forEach((coun) => {
        country.forEach((allcountry) => {
          if (allcountry.name === coun) {
            country_ids.push({ country_id: allcountry.id });
          }
        });
      });
    }
    if (selectedAverages.length > 0) {
      selectedAverages.forEach((average) => {
        averages.push({ average });
      });
    }
    const requestBody = {
      countries: country_ids,
      models: model_ids,
      averages: averages,
      page: page,
      perPage: 15,
    };
    fetch(`https://api.index911.com/api/cars/search/cars_search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'client-access-token': 'HS512384256562345690',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Request failed.');
        }
      })
      .then((data) => {
        setFilteredData(data.data.cars);
        if (data.data.cars.length > 0) {
          const carsWithMultipliedPrice = data.data.cars.map((car) => ({
            ...car,
            multipliedPrice: car.currency_converter_price * car.price,
          }));

          setUsers(carsWithMultipliedPrice);
        } else {
          toast.dismiss();
          toast.error("No data found. Please select another option.");
          setUsers([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      return;
    }
    getDataOnFilterUpdate();
  }, [checkedItems, checkedItem, checkedAverage]);
  const filterBySearch = (event) => {
    const query = event.target.value;
    var updatedList = [...optionList];
    updatedList = updatedList.filter((item) => {
      return item.model_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilteredList(updatedList);
  };

  const filterBySearch1 = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    const filtered = country.filter((c) =>
      c.name.toLowerCase().includes(searchQuery)
    );
    setFilteredCountry(filtered);
  };
  const handleCompareClick = (car) => {
    navigate('/compare-cars', { state: { carsToCompare: [car] } });
  };

  const searchData = (model_id, country_id, page = 1, perPage = 15) => {
    const url = `https://api.index911.com/api/search/cars/cars_search/?model_id=${model_id}&country_id=${country_id}&page=${page}&per_page=${perPage}`;
    const headers = {
      'Content-Type': 'application/json',
      'client-access-token': 'HS512384256562345690',
    };
    fetch(url, { headers })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'Ok') {
          const cars = data.data.cars;
          if (cars) {
            setUsers((prevUsers) => [...prevUsers, ...cars]);
            if (cars.length < perPage) {
              setHasMoreItems(false);
            }
          } else {
            console.error('No cars found.');
          }
        } else {
          console.error(data.message);
        }
      })
      .catch((error) => console.error(error));
  };

  const loadMore = () => {
    setPage(page + 1);
    setPerPage(perPage);
    const selectedModels = Object.keys(checkedItems).filter((key) => checkedItems[key]);
    const selectedCountries = Object.keys(checkedItem).filter((key) => checkedItem[key]);
    const selectedAverages = Object.keys(checkedAverage).filter((key) => checkedAverage[key]);
    if (selectedModels.length > 0 || selectedCountries.length > 0 || selectedAverages.length > 0) {
      getDataOnFilterUpdate(model_id, country_id, page + 1, perPage);
    }
    else { searchData(model_id, country_id, page + 1, perPage); }
  };
  useEffect(() => {
    if (location.state && location.state.users) {
      const parsedUsers = JSON.parse(location.state.users);
      const updatedUsers = parsedUsers.map(user => {
        const multipliedPrice = user.currency_converter_price * user.price;
        return { ...user, multipliedPrice };
      });
      setUsers(updatedUsers);
      setPage(location.state.page);
      setPerPage(location.state.perPage);
      setModel_id(location.state.model_name);
      setCountry_id(location.state.country_name);
    } else {
      searchData(model_id, country_id);
    }
  }, [location.state, model_id, country_id]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true, // Enable swiping to navigate through slides
    beforeChange: (current, next) => setCurrentImageIndex(next) // Update current index before slide change
  };




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <>
      <ToastContainer position='top-center' />
      <section>
        {/* <MetaTags>
          <link rel="canonical" href={`https://www.index911.com${currentUrl}`} />
  </MetaTags> */}
        <Container>
          <Row style={{ marginTop: "20px" }}>
            <Col md={9} sm={7} className="order-md-2 order-sm-1">
              <div className='border-custom'>
                {Object.entries(checkedItems)
                  .filter(([key, value]) => value)
                  .map(([key, value]) => key)
                  .join(', ')}
              </div>

              <div className='border-custom'>
                {Object.entries(checkedItem)
                  .filter(([key, value]) => value)
                  .map(([key, value]) => key)
                  .join(', ')}
              </div>

              {isLoading ? <LoadingSpinner /> :
                <div>
                  {users.slice(0, page * perPage).map((user, index) => {
                    return (
                      <Row>
                        {users.map((user, index) => (
                          <div className="col-md-4" key={index}>
                            <div className='p-3 searched-products-details mb-3 '>
                              <div className="image-container">
                                <img
                                  src={`${basePath}${user.images[0]}`}
                                  alt="car-Img"
                                  onClick={() => handleImageClick(0, user)}

                                />
                                <div className="image-overlay">
                                  <div>

                                  </div> &nbsp;
                                  <span className="image-counter">{`${1}/${user.images.length}`}</span>
                                </div>
                              </div>

                              <div>
                                {user.title !== null ? (
                                  <h3 className='mb-md-2 mb-2 mt-2 mt-md-0'><span style={{ fontSize: "15px" }}><b>{user.title}</b></span></h3>
                                ) : null}
                                <Row>

                                  <div className="col-md-6">


                                    {user.currency_name && (
                                      <p className="takenC mb-2 me-2 text">
                                        <b>Price &nbsp;</b> <b> {user.currency_name}</b>
                                      </p>
                                    )}
                                    {user.price && (
                                      <p className="takenC mb-2 me-2 text">
                                        {parseFloat(user.price).toLocaleString()}
                                      </p>
                                    )}


                                  </div>
                                  <div className="col-md-6">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="d-flex align-items-center">
                                        {user.multipliedPrice !== undefined ? (
                                          <p className="takenC mb-0 me-2 text"><b>Price</b> &nbsp; <b>€ </b>
                                            {user.multipliedPrice.toFixed(3)}</p>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                                <div className="d-flex  align-items-center mt-2">
                                  <Link to={"/product-details/" + user.slug}>
                                    <button className='more-details-btn me-2' >More Details</button>
                                  </Link>
                                  <button className="compare-btn" onClick={() => handleCompareClick(user)}>
                                    Compare
                                  </button>
                                </div>
                                <div className="visit mt-2">
                                  <a href={user.url} target="_blank" rel="noopener noreferrer">visit the car - {user.url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im)[1]}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Row>
                    );
                  })}

                  {hasMoreItems && users.length > 0 && (
                    <div style={{ textAlign: "center" }}>
                      <button className='more-details-btn me-2' onClick={loadMore} style={{ marginTop: "10px", marginBottom: "15px" }}>Load More</button>
                    </div>
                  )}
                </div>
              }
            </Col>
            <Col md={3} sm={5} className="order-md-1 order-sm-2">

              <div className="App appcus choosebudget p-3 mb-3">
                <h4 className='h5'>Search model</h4>
                <div className='p-2 mb-3 border' style={{ borderRadius: "10px" }}>
                  <i className='fas fa-search' />
                  <input type='text'
                    className='border-0 ps-2'
                    placeholder='e.g Porsche'
                    onChange={filterBySearch}
                  />
                </div>

                {isLoading ? <LoadingSpinner /> :
                  <div className='add-scroll'>
                    {filteredList.map((item, index) => {
                      return (
                        <div className="toppings-list-item">
                          <div className="left-section d-flex align-items-center mb-2">
                            <input className='form-check-input rounded-0 me-2 mt-0'
                              type="checkbox"
                              id={`custom-checkbox-${index}`}
                              name={item.model_name}
                              checked={checkedItems[item.model_name]}
                              onChange={handleChangeModels}
                            />
                            <label>{item.model_name}</label>
                          </div>
                        </div>
                      );
                    })}
                    <br />
                  </div>
                }
              </div>
              {/* <Button className='btn addcompare mb-2 d-block rounded-0 w-100 border-0'>Add to Compare</Button> */}
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div className="App appcus p-lg-3 p-2 mb-4">
                  <h4 className="h5">Choose country</h4>
                  <div className='p-2 mb-3 border ' style={{ borderRadius: "10px" }}>
                    <i className='fas fa-search' />
                    <input type='text'
                      className='border-0 ps-2'
                      placeholder='e.g Poland '
                      onChange={filterBySearch1}
                    />
                  </div>

                  <div className="">
                    {filteredCountry.map((countries) => (
                      <div key={countries.id} className="country-card1">
                        <label className="country-label d-flex align-items-center">
                          <input
                            className="form-check-input rounded-0 me-1 mt-0"
                            type="checkbox"
                            id={`checkbox-${countries.name}`}
                            name={countries.name}
                            checked={checkedItem[countries.name]}
                            onChange={handleChangeCountries}
                            style={{ padding: "7px 9px 5px 4px" }}
                          />
                          <img
                            src={`${basePath}${countries.image_path}`}
                            alt="country-img"
                            className="country-img"
                            style={{ width: "25px", marginLeft: "20px" }}
                          />
                          <span className="country-namess" style={{ marginLeft: "10px" }} >{countries.name}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

              )}
              <div className="App appcus choosebudget p-3 mb-4" >
                <h4 className="h5">Budget</h4>

                <div style={{ position: "relative" }}>
                  <Typography variant="body2" style={{ position: 'absolute', top: 0, left: 0 }}>
                    <span style={{ color: "#24272C" }}>  25 SEK</span>
                  </Typography>
                  <Slider
                    getAriaLabel={() => 'Temperature range'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    max="100000"
                    style={{ marginTop: 20 }}
                  />
                  <Typography variant="body2" style={{ position: 'absolute', top: 0, right: 0 }}>
                    <span style={{ color: "#24272C" }}>    100,000   SEK</span>
                  </Typography>
                </div>

                <div className='add-scroll'>
                  {isLoading ? <LoadingSpinner /> :
                    <div className="App appcus p-lg-3 p-2 mb-4">

                      <p className='price-range' >What is your price range?</p>

                      {average.map((price) => {
                        return (
                          <div key={average.id}>
                            <div>
                              <div className='d-flex align-items-center '>
                                <input
                                  className="form-check-input rounded-0 me-1 mt-0"
                                  type="checkbox"
                                  id="flexCheckDefault"
                                  name={price.average_price}
                                  checked={checkedAverage[price.converted_price]}
                                  onChange={handleChangeAverage}
                                />
                                &nbsp;
                                <p className="takenprice mb-0 porche-price-cus" style={{ fontSize: "15px" }}> {price.converted_price} </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }
                </div>
              </div>
              {/* <div className="App appcus p-lg-3 p-2 mb-md-2 mb-2 mt-2 mt-md-0">
                <h4 className='h5'>Compare list</h4>
                <div>{Object.entries(checkedItems).filter(([key, value]) => value).map(([key, value]) => key).join(' ')}</div>
              </div>
                <Button className='btn addcompare mb-2 d-block rounded-0 w-100 border-0'>Add to Compare</Button> */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default Products;




