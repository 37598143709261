import React, { useState, useEffect } from 'react';
import './resetPassword.css';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty, isUndefined } from "lodash";
import Button from '@mui/material/Button';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPassword() {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordError, setPasswordError] = useState();
  const [confirmPasswordError, setConfirmPasswordError] = useState();
  const navigate = useNavigate();
  const { token } = useParams();

  const validateLogin = () => {
    if (!isUndefined(password)) {
      if (isEmpty(password)) {
        setPasswordError("Password is a required field")
      }
      else if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/.test(password))) {
        setPasswordError("Please enter valid Password")
      } else {
        setPasswordError("")
      }
    }
    if (!isUndefined(confirmPassword)) {
      if (isEmpty(confirmPassword)) {
        setConfirmPasswordError("Password is a required field")
      }
      else if (confirmPassword !== password) {
        setConfirmPasswordError("Password-does-not-match")
      } else {
        setConfirmPasswordError("")
      }
    }
  }
  useEffect(() => {
    validateLogin();
  }, [password, confirmPassword]);

  const changePassword = event => {
    event.preventDefault()
    fetch(`https://api.index911.com/api/auth/password/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'client-access-token': 'HS512384256562345690',
      },
      body: JSON.stringify({
        token: token,
        password: password
      })
    }).then(res => res.json())
      .then(data => {
        if (data.status === "Ok") {
          toast.success(data.message)
          setTimeout(() => {
            toast.dismiss()
            navigate("/login")
          }, 2000)
        }
        else {
          alert(data.message);
          // console.log("fkdf")
        }
      })
  } 
  return (
    <section  className='login-img'>
      <ToastContainer position='top-center' />
      <div>
        <div className='row'>
          <div className='col-md-5 mx-auto'>
            <form className='text-center form  py-5 my-5'>
              <input type="text" className='mb-3' placeholder='Set-password' style={{ width: "50%" }} onChange={(e) => setPassword(e.target.value)} value={password} />
              <span className="text-danger">{passwordError}</span>
              <input type="text" className='' placeholder='Confirm-your-password' style={{ width: "50%" }} onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} />
              <span className="text-danger">{confirmPasswordError}</span>
              <div className='mt-4'>
                <Button className='Search-btn text-capitalize' variant="contained"
                  disabled={
                    isUndefined(password) || isUndefined(confirmPassword)
                    || !(isEmpty(passwordError) && isEmpty(confirmPasswordError))
                  }
                  onClick={changePassword}
                  style={{ color: "white", width: "30%" }} >Continue</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ResetPassword;