import React, { useEffect, useState } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import "./SearchedCar.css";

const basePath = 'http://api.index911.com/';

const SearchedCar = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [images, setImages] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = 'https://api.index911.com/api/cars/popular-searches';
                const headers = {
                    'Content-Type': 'application/json',
                    'client-access-token': 'HS512384256562345690',
                };
                const response = await fetch(url, { headers });
                const data = await response.json();
                if (data.status === 'Ok') {
                    const cars = data.data.cars;
                    if (cars) {
                        const carsWithMultipliedPrice = cars.map((car) => ({
                            ...car,
                            multipliedPrice: car.currency_converter_price * car.price,
                        }));
                        setImages(carsWithMultipliedPrice);
                    } else {
                        toast.dismiss();
                        toast.error('No cars found.');
                    }
                } else {
                    toast.dismiss();
                    toast.error(data.message);
                }
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleModelSelect = (model) => {
        setSelectedModel(model);
        fetchDataForModel(model);
    };

    const fetchDataForModel = async () => {
        try {
            // const url = `https://api.index911.com/api/cars/${model}`;
            const url = 'https://api.index911.com/api/cars/popular-searches';
            const headers = {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            };
            const response = await fetch(url, { headers });
            const data = await response.json();
            if (data.status === 'Ok') {
                const cars = data.data.cars;
                if (cars) {
                    const carsWithMultipliedPrice = cars.map((car) => ({
                        ...car,
                        multipliedPrice: car.currency_converter_price * car.price,
                    }));
                    setImages(carsWithMultipliedPrice);
                } else {
                    toast.dismiss();
                    toast.error('No cars found for selected model.');
                }
            } else {
                toast.dismiss();
                toast.error(data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to fetch data for selected model.');
        }
    };

    const handleImageClick = (car) => {
        navigate('/compare-cars', { state: { carsToCompare: [car] } });
    };

    const handleCompareClick = (car) => {
        navigate('/compare-cars', { state: { carsToCompare: [car] } });
    };

    return (
        <div className='searched-car-section'>
            <ToastContainer />
            <Row>
                <Col>
                    <h3 className='trusted-car mt-2 mx-3' >The Most Searched Cars</h3>
                </Col>
            </Row>
            <div className='mx-3'>
                <Nav variant="underline" defaultActiveKey="/">
                    <Nav.Item>
                        <Nav.Link href="" className='navLinks' onClick={() => handleModelSelect('Porche 911')}>Porche 911</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-1" className='navLinks' onClick={() => handleModelSelect('Porche 912')}>Porche 912 </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link eventKey="link-2" className='navLinks' onClick={() => handleModelSelect('Porche 913')}>Porche 913 </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link eventKey="link-3" className='navLinks' onClick={() => handleModelSelect('Porche 914')}>Porche 914 </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-4" className='navLinks' onClick={() => handleModelSelect('Porche 915')}>Porche 915</Nav.Link>
                    </Nav.Item>

                </Nav>
            </div>

            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <section>
                    <div className="container">
                        <div className="most-searched-content1 mt-3">
                            <div className="row">
                                {images.slice(0, 3).map((user, index) => (
                                    <>
                                        <div className="col-lg-4 col-md-6 mb-3">
                                            <div className="car-card" style={{ height: "100%" }}>
                                                <div className="card_image">
                                                    <img
                                                        src={`${basePath}${user?.image_path[0]}`}
                                                        alt="car-Img"
                                                        onClick={() => handleImageClick(0, user)}
                                                        style={{ minHeight: "250px", width: "100%", objectFit: "cover", objectPosition: "center" }}
                                                    />
                                                </div>
                                                <div className="card_text">
                                                    <h6> {user.title && (
                                                        <p className="monthalyt text ">
                                                            <b>{user.title}</b>
                                                        </p>
                                                    )}</h6>
                                                    <div className="row">
                                                        {user.model_name || user.year ? (
                                                            <div className="row">
                                                                {user.model_name && (
                                                                    <div className="col-md-6">
                                                                        <div className="d-flex align-items-center mb-2  justify-content-between">
                                                                            <p className="takenC text text-start">
                                                                                <b>Model &nbsp;</b> {user.model_name}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {user.year && (
                                                                    <div className="col-md-6">
                                                                        <div className="d-flex align-items-center mb-2 justify-content-between">
                                                                            <p className="takenC text text-start">
                                                                                <b>Year &nbsp;</b> {user.year}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : null}

                                                        {user.mileage || user.transmission ? (
                                                            <div className="row">
                                                                {user.mileage && (
                                                                    <div className="col-md-6">
                                                                        <div className="d-flex align-items-center mb-2 justify-content-between">
                                                                            <p className="takenC text text-start">
                                                                                <b>Mileage &nbsp;</b> {user.mileage}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {user.transmission && (
                                                                    <div className="col-md-6">
                                                                        <div className="d-flex align-items-center mb-2 justify-content-between">
                                                                            <p className="takenC text text-start">
                                                                                <b>Transmission &nbsp;</b> {user.transmission}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : null}

                                                        { /*   {user.engine_volume || user.power ? (
                                                            <div className="row">
                                                                {user.engine_volume && (
                                                                    <div className="col-md-6">
                                                                        <div className="d-flex align-items-center mb-2 justify-content-between">
                                                                            <p className="takenC text text-start">
                                                                                <b>Engine volume &nbsp;</b> {user.engine_volume}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {user.power && (
                                                                    <div className="col-md-6">
                                                                        <div className="d-flex align-items-center mb-2 justify-content-between">
                                                                            <p className="takenC text text-start">
                                                                                <b >Power &nbsp;</b> {user.power}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                                ) : null} */}

                                                        <div className="col-md-6">
                                                            <div className="align-items-center mb-2 ">
                                                                <p className='pricess align-items-center mb-2 justify-content-between'><b>Price </b> &nbsp; <b>{user.currency_name}</b>  {user.price}   </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="align-items-center mb-2 ">
                                                                <p className='pricess align-items-center mb-2 justify-content-between'><b>Price </b> &nbsp; <b>€</b>  {user.multipliedPrice.toFixed(3)}  </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="card_buttons mx-3">
                                                    <div className="d-flex mb-3 latest-car-button">
                                                        <Link to={"/product-details/" + user.slug} >
                                                            <button className="more-details-btn me-2" >
                                                                More Details
                                                            </button>
                                                        </Link> &nbsp;
                                                        <button className="compare-btn" onClick={() => handleCompareClick(user)}>
                                                            Compare
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <p className='mx-4 mt-3'>View All Cars</p>
        </div>
    )
}

export default SearchedCar;
