import React, { useEffect, useState } from 'react';
import "./Register.css";
import { Row, Col, Card, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty, isUndefined } from "lodash";
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState("");
  const [first_name, setFirst_name] = useState();
  const [first_nameError, setFirst_nameError] = useState();
  const [last_name, setLast_name] = useState();
  const [last_nameError, setLast_nameError] = useState();
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState();

  const validateLogin = () => {
    if (!isUndefined(email)) {
      if (isEmpty(email)) {
        setEmailError("Email is a required field")
      }
      else if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
        setEmailError("Please enter a valid email");
      }
      else {
        setEmailError("")
      }
    }
    if (!isUndefined(first_name)) {
      if (isEmpty(first_name)) {
        setFirst_nameError("First  name is a required field")
      }
      else if (!(/^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{2,}$/.test(first_name))) {
        setFirst_nameError("Please enter valid name")
      } else {
        setFirst_nameError("")
      }
    }
    if (!isUndefined(last_name)) {
      if (isEmpty(last_name)) {
        setLast_nameError("Last name is a required field")
      }
      else if (!(/^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{2,}$/.test(last_name))) {
        setLast_nameError("Please enter valid name")
      } else {
        setLast_nameError("")
      }
    }
    if (!isUndefined(password)) {
      if (isEmpty(password)) {
        setPasswordError("Password is a required field")
      }
      else if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/.test(password))) {
        setPasswordError("Please enter valid Password")
      } else {
        setPasswordError("")
      }
    }
  }
  useEffect(() => {
    validateLogin();
  }, [email, password, first_name, last_name]);

  const registration = event => {
    event.preventDefault()
    fetch(`https://api.index911.com/api/auth/email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'client-access-token': 'HS512384256562345690',
      },
      body: JSON.stringify({ last_name: last_name, first_name: first_name, email: email, password: password })
    }).then(res => res.json())
      .then(data => {
        if (data.status === "Ok") {
          toast.success(data.message)
          localStorage.setItem('user', JSON.stringify(data));
          // console.log(JSON.stringify(data), "KKKKKKKKKKKKKKKKKKKK")
          // console.log(data, "LLLLLLLLLLLLLLL")
          setTimeout(() => {
            toast.dismiss()
            navigate('/email-verification')
          }, 1000)
        } else {
          toast.dismiss();
          toast.error(data.message)
        }
      })
      .catch(err => {
        toast.dismiss()
        toast.error(err.message)
      })
  }

  return (
    <div>
      <section className='register-img'>
        <ToastContainer position='top-center' />
        <Container>
          <Row>
            <Col>
            </Col>
            <Col xs={12} md={6} lg={5} sm={12}>
              <Card className='card-register p-md-4 p-2 my-5'  >
                <Card.Body>
                  <form className='register-form'>
                    <h1 className='mb-5'>Register</h1>
                    <input className='px-3' type="text" placeholder='First Name' onChange={(e) => setFirst_name(e.target.value)}
                      value={first_name} />
                    <span className="text-danger">{first_nameError}</span>
                    <br />
                    <input className='px-3 mt-3' type="text" placeholder='Last Name' onChange={(e) => setLast_name(e.target.value)}
                      value={last_name} />
                    <span className="text-danger">{last_nameError}</span>
                    <br />
                    <input className='px-3 mt-3' type="email" placeholder='Your Email' onChange={(e) => setEmail(e.target.value)}
                      value={email} />
                    <span className="text-danger">{emailError}</span>  <br />
                    <input className='px-3 mt-3 ' type="password" placeholder='Your Password' onChange={(e) => setPassword(e.target.value)}
                      value={password} autoComplete='off' />
                    <span className="text-danger">{passwordError}</span>
                    <br />
                    <div className='mt-3'>
                      <Button className='register-btn' variant="contained"
                        disabled={
                          isUndefined(first_name) || isUndefined(last_name) || isUndefined(email)
                          || isUndefined(password) || !(isEmpty(emailError) && isEmpty(passwordError)) && (isEmpty(setFirst_nameError)) && (isEmpty(last_nameError))
                        }
                        onClick={registration}>Register</Button></div>
                    <div className='alreay-account justify-content-between mt-4' style={{ justifyContent: "center" }}>
                      <p>Already have an account?</p>
                      <h6>  <Link to="/login">Login</Link>  </h6>
                    </div>
                  </form>
                </Card.Body>
              </Card>
            </Col>
            <Col>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
export default Register;