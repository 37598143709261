import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Cars from "../../assets/images/964.jpeg";
import Cars1 from "../../assets/images/993.jpg";
import Cars2 from "../../assets/images/997.jpg";
import Cars3 from "../../assets/images/991.jpg";
import Cars4 from "../../assets/images/911-classic.jpg";
import Cars5 from "../../assets/images/996.jpg";
import "./ComparingCars.css";

const carData = [
    { name: 'Porsche 964', image: Cars },
    { name: 'Porsche 993', image: Cars1 },
    { name: 'Porsche 997', image: Cars2 },
    { name: 'Porsche 991', image: Cars3 },
    { name: 'Porsche 911 classic', image: Cars4 },
    { name: 'Porsche 996', image: Cars5 },
];

const ComparingCars = () => {
    return (
        <section className='comparing-car mt-3'>
            <Container>
                <Row>
                    {carData.map((car, index) => (
                        <Col md={2} key={index}>
                            <div className='model-names'>
                                <img src={car.image} alt={car.name} style={{ width: '100%' }} />
                                <p className='model-car-name mt-2' >{car.name}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default ComparingCars;
