import React, { useState } from 'react'
import { isEmpty, isUndefined } from 'lodash';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Textarea } from 'evergreen-ui';
import LoadingSpinner from './LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import "./Contact.css"

const ContactUS = () => {
    let navigate = useNavigate();
    const [name, setName] = useState();
    const [nameError, setNameError] = useState();
    const [email, setEmail] = useState();
    const [emailError, setEmailError] = useState();
    const [message, setMessage] = useState();
    const [messageError, setMessageError] = useState();

    const contactUs = event => {
        event.preventDefault()
        fetch(`https://api.index911.com/api/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
            body: JSON.stringify({ name: name, email: email, message: message })
        }).then(res => res.json())
            .then(data => {
                if (data.status === "Ok") {
                    toast.success(data.message)
                    // console.log(data, "CONTACTUS")
                    setTimeout(() => {
                        toast.dismiss()
                        navigate('/')
                    }, 1000)
                } else {
                    toast.dismiss();
                    toast.error(data.message)
                }
            })
            .catch(err => {
                toast.dismiss()
                toast.error(err.message)
            })
    }
    return (
        <section className="Contact-Us query">
            <div className="container">
                <div className="contact-us-content">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <h4 className="text-center">Any query please Contact Us</h4>
                            <form action="" className='mt-5'>
                                <div className="row">
                                    <div className="col-sm-6 mb-4">
                                        <input
                                            className="form-control border-black bg-transparent"
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Enter your Name"
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <input
                                            className="form-control border-black bg-transparent"
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Enter your Email"
                                        />
                                    </div>
                                    <div className="col-sm-12 pt-3">
                                        <textarea
                                            className="form-control border-black bg-transparent"
                                            name="message"
                                            id="message"
                                            rows={4}
                                            placeholder="Enter your message"
                                            defaultValue={""}
                                        />
                                    </div>
                                </div>
                                <div className="submit text-center pt-3">
                                    <button type="submit" className="btn btn_primary  contact-submit-button px-5">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default ContactUS