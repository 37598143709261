import React, { useEffect, useState } from 'react';
import "./ForgotPassword.css";
import Button from '@mui/material/Button';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { isUndefined, isEmpty } from 'lodash';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const ForgetPassword = () => {

  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  let navigate = useNavigate()
  const validateLogin = () => {
    if (!isUndefined(email)) {
      if (isEmpty(email)) {
        setEmailError("Email is a required field")
      }
      else if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
        setEmailError("Please enter a valid email");
      } else {
        setEmailError("")
      }
    }
  }
  useEffect(() => {
    validateLogin();
  }, [email]);
  const sendPassword = event => {
    event.preventDefault()
    fetch(`https://api.index911.com/api/auth/password/forget`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'client-access-token': 'HS512384256562345690',
      },
      body: JSON.stringify({
        email: email,
      })
    }).then((res) => res.json())
      .then((data) => {
        if (data.status === "Ok") {
          toast.success(data.message);
          // console.log("fkdf")
          setTimeout(() => {
            toast.dismiss()
            navigate("/");
          }, 1000)
        } else {
          toast.error(data.message)
        }
      })
  }
  return (
    <div>
      <section className='forget-pswd' >
        <ToastContainer position='top-center' />
        <Container>
          <Row>
            <Col>
            </Col>
            <Col xs={12} md={6} lg={5} sm={12}>
              <Card className='cards p-md-4 p-2 my-5 border-0 rounded-0' >
                <Card.Body>
                  <form className='forgot-form '>
                    <h4 className='h5 mb-4'>Did you say forgot password?Don’t <br /> <span>panic, we're here</span></h4>
                    <p >Enter the email you usually use for the account</p>
                    <input className='px-3' type="text" placeholder='Your Email' onChange={(e) => setEmail(e.target.value)} value={email} />

                    <span className="text-danger">{emailError}</span>
                    <div className='mt-4'>
                      <Button className='submit-btn text-capitalize rounded-0' variant="contained"
                        onClick={sendPassword}
                        disabled={
                          isUndefined(email)
                          || !(isEmpty(emailError))
                        } >Continue</Button>
                    </div> <br />
                    <div className='return'>
                      <p > <Link to="/login">Return to login page</Link>  </p>
                    </div>
                  </form>
                </Card.Body>
              </Card>
            </Col>
            <Col>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
export default ForgetPassword;
