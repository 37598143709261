import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import ForgotPassword from "./components/ForgotPassword/ForgetPassword";
import EmailVerification from "./components/EmailVerification";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Home from "./components/Home/Home";
import Compare from "./components/CompareCars/Compare";
import Products from "./components/Product/Products";
import ProductDetails from "./components/Product/ProductDetails";
import AboutUs from "./components/About/AboutUs";
import ContactUS from "./components/Contact/ContactUs";
import Privacy from "./components/Privacy/Privacy";
import NotFound from "./components/NotFound";
import Models from "./components/Models/Models";
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/" element={<Home />} />
          <Route path="/compare-cars" element={<Compare />} />
          <Route path="/products/" element={<Products />} />
          <Route path="/product-details/:slug" element={<ProductDetails />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUS />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/porsche-model" element={<Models />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
