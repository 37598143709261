import React, { useEffect, useState } from 'react';
import HomeBlog from "./HomeBlog";
import { Button, Autocomplete, TextField } from '@mui/material';
import { Col, Row, Container, Card } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import "./Home.css";
import TrustedCar from './TrustedCar';
import ContactUS from './ContactUS';
import Map from "../../assets/images/map.png";
import LatestCar from './LatestCar';
import SearchedCar from './SearchedCar';
import HomeMap from './HomeMap';
import LoadingSpinner from './LoadingSpinner';
import ComparingCars from './ComparingCars';


const basePath = "http://api.index911.com/";

const Home = () => {
    const [perPage, setPerPage] = useState(15);
    const [page, setPage] = useState(1);
    let navigate = useNavigate();
    const [country_name, setCountry_name] = useState("");
    const [model_name, setModel_name] = useState("");
    const [showRequired, setShowRequired] = useState(false);
    const [optionList, setOptionList] = useState([{}]);
    const [country, setCountry] = useState([{}]);
    const [isLoading, setIsLoading] = useState(false);
    const [step1Background, setStep1Background] = useState('#24272C');
    const [step2Background, setStep2Background] = useState('white');
    const [mapVisible, setMapVisible] = useState(false);


    const handleChangeCountry = (event, value) => {
        if (!value) {
            setShowRequired(true);
        } else {
            setShowRequired(false);
            setCountry_name(value.id)
        }
    };

    const handleChangeModels = (event, value) => {
        if (!value) {
            setShowRequired(true);
        } else {
            setShowRequired(false);
            setModel_name(value.model_id === "0" ? "0" : value.id);
        }
    };
    const handleChangeModels1 = (event, value) => {
        if (!value) {
            setShowRequired(true);
        } else {
            setStep1Background('#24272C');
            setStep2Background('white');
            setShowRequired(false);
            setModel_name(value.model_id === "0" ? "0" : value.id);

        }
    };

    const handleChangeCountry1 = (event, value) => {
        if (!value) {
            setShowRequired(true);
        } else {
            setStep1Background('white');
            setStep2Background('#24272C');
            setShowRequired(false);
            setCountry_name(value.id);

        }
    };

    const searchCountry = () => {
        setIsLoading(true);
        fetch(`https://api.index911.com/api/cars/getAllCountries`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'client-access-token': 'HS512384256562345690',
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === "Ok") {
                    setCountry(data.data);
                    setIsLoading(false);
                }
                else {
                    toast.dismiss();
                    toast.error(data.message)
                }
            });
    };

    const searchModels = () => {
        setIsLoading(true);
        fetch(`https://api.index911.com/api/cars/model`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === "Ok") {
                    const filteredOptions = data.data.filter((option) => option.model_name.includes("911"));
                    const textOption = { model_name: "911 series", model_id: "0" };
                    const modifiedOptions = [textOption, ...filteredOptions];
                    setOptionList(modifiedOptions);
                    setIsLoading(false);
                } else {
                    toast.dismiss();
                    toast.error(data.message);
                }
            });
    };

    const searchData = () => {
        if (!model_name) {
            toast.info("Please select the model.");
            return;
        }
        if (!country_name) {
            toast.info("Please select the country.");
            return;
        }
        const url = `https://api.index911.com/api/search/cars/cars_search/?model_id=${model_name}&country_id=${country_name}&page=${page}&per_page=${perPage}`;
        const headers = {
            'Content-Type': 'application/json',
            'client-access-token': 'HS512384256562345690',
        };

        fetch(url, { headers })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === "Ok") {
                    const cars = data.data.cars;
                    if (cars && cars.length > 0) {
                        navigate('/products', { state: { users: JSON.stringify(data.data.cars), page, perPage, model_name, country_name } });
                    } else {
                        if (cars && cars.length === 0) {
                            toast.error("No cars found.");
                        } else {
                            toast.error("Empty response received.");
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        searchModels();
        searchCountry();
    }, []);

    return (
        <div>
            <ToastContainer />
            <section className="banner-content">
                <Container>
                    <Row>
                        <Col md={4}>
                            <Card style={{ borderRadius: "10px", height: '100%' }}>
                                <Card.Body>
                                    <form style={{ position: " relative", zIndex: "0" }}>
                                        <div className='search-form p-md-4 p-2 auto '>
                                            <div className='d-flex autocompltes-steps'>
                                                <Button
                                                    className="mb-3 step1-btn"
                                                    variant="contained"
                                                    style={{ backgroundColor: step1Background, color: step1Background === '#24272C' ? 'white' : 'black' }}
                                                >
                                                    Step 1 Model
                                                </Button> &nbsp;&nbsp;
                                                <Button
                                                    className="mb-3 step1-btn"
                                                    variant="contained"
                                                    style={{ backgroundColor: step2Background, color: step2Background === '#24272C' ? 'white' : 'black' }}
                                                >
                                                    Step 2 Country
                                                </Button>
                                            </div>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={optionList}
                                                onChange={handleChangeModels1}
                                                getOptionLabel={(option) => option.model_name}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Find Your 911" required={true} error={showRequired && !model_name} />
                                                )}
                                            />
                                            <Autocomplete
                                                className='mt-3'
                                                disablePortal
                                                id="combo-box-demo"
                                                options={country}
                                                onChange={handleChangeCountry1}
                                                // sx={{ width: 300 }}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Country" required={true} error={showRequired && !country_name} />
                                                )}
                                            />
                                        </div>
                                        <div className='searh-btns my-2 pb-md-4 pb-2 pt-0 text-center' >
                                            <Button className='Search-btn text-capitalize'
                                                onClick={searchData}
                                                style={{ width: "85%" }}
                                                variant="contained" >
                                                Search
                                            </Button>
                                        </div>
                                    </form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <div>
                                <div className='welcome'>
                                    <p className='text-white'>welcome to the world of <br /><span className='prices'> Porsche 911 prices</span><br />View and compare prices of Porsche<br />911 models</p>
                                </div>
                                <img src={Map} alt='map' onClick={() => setMapVisible(!mapVisible)} />
                                <p className='text-white text-end view-map' onClick={() => setMapVisible(!mapVisible)} >View Full Map</p>
                            </div>
                        </Col>
                        <Col md={4} className="justify-content-center mb-5">
                            <div className="country-card-container">
                                <Row md={5} className="justify-content">
                                    {!country || country.length === 0 ? (
                                        <Col>
                                            <div className="loading-container">
                                                <LoadingSpinner />
                                            </div>
                                        </Col>
                                    ) : (
                                        country.slice(0, 5).map((country, index) => (
                                            <Col key={country.id} className="">
                                                <div className="country-card1">
                                                    <img
                                                        src={`${basePath}${country.image_path}`}
                                                        alt="country-img"
                                                        className="country-img"
                                                    />
                                                    <span className="country-name1 mt-3 text-white">
                                                        {country.name}
                                                    </span>
                                                </div>
                                            </Col>
                                        ))
                                    )}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    {mapVisible && (
                        <div className='col-md-12'>
                            <div id="homemapdata" >
                                <button onClick={() => setMapVisible(false)} style={{ position: 'absolute', top: 10, right: 10, zIndex: 9999 }}>X</button>
                                <Link to="/porsche-model">
                                    <HomeMap />
                                </Link>
                            </div>
                        </div>
                    )}
                </Container>
            </section>
            <Container>
                <SearchedCar />
                <Row className='mt-3'>
                    <Col>
                        <div className='home-text'>
                            <h5>Your Search for the Most Accurate 911 Porsche Price Portal Ends Here!</h5>
                            <p className='mt-3'>For all Porsche lovers, we've crafted the most accurate and real-time car compare platform. At Index911, you can view and compare the specifications and prices of your favorite 911 Porsches to make an informed decision.</p>
                            <p>Porsche has created a legacy for itself by delivering soul-thriving, mind-blowing racing machines for their fans. To ensure everyone gets the right details of their dream car where they live, our team has done the hard work for you. At Index911, you get all the significant and as well as intricate details of your Porsche.</p>
                            <p>With years of experience and undying passion for 911 Porsches , our team is committed to deliver you an unparalleled experience. Whether it's your first car or you're adding another beast to your collection, we've got you covered.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className='home-text mt-3'>
                        <Col>
                            <h3 className='trusted-car mx-3'>Recommended Cars For You</h3>
                        </Col>
                        <LatestCar />
                    </div>
                </Row>
                <Row>
                    <div className='home-text mt-3'>
                        <Col>
                            <h3 className='trusted-car  mx-3'>Latest Cars </h3>
                        </Col>
                        <LatestCar />
                    </div>
                </Row>
                <div className='mt-2 mb-5'>
                    <ContactUS />
                </div>

                <Row className='mt-3'>
                    <Col>
                        <div className='home-text'>
                            <h5>All Important Details in One Place</h5>
                            <p className='mt-3'>Whether it is the price of Porsche 911 Turbo S, or its top speed we have you covered. On our site we have a thorough comparison of all key specifications and features found on different Porsche 911 models. With everything conveniently provided at one spot, you won't have to spend hours scouring websites for information.</p>
                            <h5 className='mt-4'>Detailed Comparison of Models</h5>
                            <p className='mt-3'>Be it the Porsche 911 GT3 or Porsche Carrera 4S, we offer a thorough comparison of all the available models. You can examine many angles-engine type, horsepower and so on to find the 911 that's right for you. All you need to do is decide which model and in which country the prices are going to be viewed. With detailed side-by-side comparisons, making an informed choice could not be easier.</p>
                            <h5 className='mt-4'>User-Friendly Interface</h5>
                            <p className='mt-3'>Index911 understands that your time is worthwhile. Thus, our site is designed to provide a convenient and intuitive experience. You can move from model to specification and back again as you please, without a hassle. Our interface is also optimized for mobile devices, so 911 Porsches can be compared on the move.</p>
                            <h5 className='mt-4'>Expert team behind the scene</h5>
                            <p className='mt-3'>Index911 is a team of Porsche enthusiasts devoted to providing our users with the most accurate information. All data on our platform is constantly updated and checked by experts in the field. In this way, trust us to provide you with the most up-to-date and reliable information for Porsche 911 models.</p>
                            <h5 className='mt-4'>Start Comparing 911 Porsches now!</h5>
                            <p className='mt-3'>Get your hands on the perfect Porsche 911 with Index911. Our platform is all set to assist you in making an informed decision about buying your dream car.</p>
                        </div>
                    </Col>
                </Row>
                {/*  <Row>
                    <div className='home-text mt-3'>
                        <Col>
                            <h3 className='trusted-car  mx-3 '>Latest Cars </h3>
                        </Col>
                        <LatestCar />
                    </div>
                </Row> */}

                <Row>
                    <div className='home-text mt-3'>
                        <Col>
                            <h3 className='trusted-car  mx-3'>Compare to Buy Right Car</h3>
                        </Col>
                        <TrustedCar />
                    </div>
                </Row>



                <Row>
                    <div className='home-text mt-3'>
                        <Col>
                            <h3 className='trusted-car  mx-3'>Compare to Buy Right Car</h3>
                        </Col>
                        <ComparingCars />
                    </div>
                </Row>
                <Row>
                    <div className='home-text mt-3'>
                        <Col>
                            <h3 className='trusted-car  mx-3'>Get trusted cars</h3>
                        </Col>
                        <TrustedCar />
                    </div>
                </Row>

                <Row className='mt-3 home-text'>
                    <Col md={9}>
                        <h3><b>Compare Prices of Porsche 911 models</b></h3>
                        <div className="country-card-container">
                            {country.map((countries) => (
                                <div key={countries.id} className="country-card">
                                    <div className='country-img-section d-flex  banner-imgesss mt-3 justify-content-center'>
                                        <div className="country-label-img d-flex flex-column align-items-center position-relative">
                                            <img
                                                src={`${basePath}${countries.image_path}`}
                                                alt="country-img"
                                                className="country-img"
                                            />
                                        </div>
                                    </div>
                                    <div className='country-content-section mt-3'>
                                        <span className="country-name mt-3">{countries.name}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col md={3} className="text-center ">
                        <form>
                            <h3><b>911 world price index</b></h3>
                            <div className='search-form mt-5 text-center'>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={optionList}

                                    onChange={handleChangeModels}
                                    getOptionLabel={(option) => option.model_name}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Find Your 911" required={true} error={showRequired && !model_name} />
                                    )}
                                />
                                <Autocomplete
                                    className='mt-3'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={country}
                                    onChange={handleChangeCountry}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Country" required={true} error={showRequired && !country_name} />
                                    )}
                                />
                            </div>
                            <div className='searh-btns my-2 pb-md-4 mt-4  mx-4 pb-2 pt-0 text-center' >
                                <Button className='Search-btn text-capitalize'
                                    onClick={searchData}
                                    variant="contained" >
                                    Search
                                </Button>
                            </div>
                        </form>
                    </Col>
                </Row>
                <div className='home-text mt-3'>
                    <HomeBlog />
                </div>


            </Container>
        </div>
    )
}

export default Home;
