import React, { useEffect, useState } from 'react';
import "./Navbar.css";
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Nav, Row } from 'react-bootstrap';
import Logo from "../../assets/images/loggo.png";
import 'bootstrap/dist/js/bootstrap.bundle.min';

function Navbar() {
    const navigate = useNavigate();
    const currentUserData = JSON.parse(localStorage.getItem("user"));
    const userName = currentUserData?.data?.first_name;
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [selectedItem, setSelectedItem] = useState("Home");

    const logOut = () => {
        try {
            localStorage.removeItem('user');
            navigate("/");
        } catch {
            navigate("/");
        }
    };

    useEffect(() => {
        const emailVerificationStatus = currentUserData?.data?.email_verified;
        if (emailVerificationStatus) {
            setIsEmailVerified(true);
        }
    }, [currentUserData]);

    const handleSelect = (itemName) => {
        setSelectedItem(itemName);
    };

    return (
        <>
            <div className="topbar">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-sm-6 col-6">
                            <div className="nav-logo text-center">
                                <Link to="/">
                                    <img src={Logo} className="img-fluid" alt="Logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 order-1 order-lg-0">
                            <form className="d-flex" role="search">
                                <input
                                    className="form-control rounded-0"
                                    type="search"
                                    placeholder="Search Cars eg. Porsche"
                                    aria-label="Search"
                                />
                                <button className="btn btn-dark rounded-0" type="submit">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        fill="currentColor"
                                        className="bi bi-search"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                    </svg>
                                </button>
                            </form>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-6 text-end">
                            <div className="login-btn d-inline-block">
                                <Link to="/login">
                                    <svg
                                        className="me-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={14}
                                        height={15}
                                        viewBox="0 0 14 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M7.13252 8.48747C9.26623 8.48747 10.9973 6.75635 10.9973 4.62264C10.9973 2.48893 9.26623 0.757812 7.13252 0.757812C4.99882 0.757812 3.2677 2.48893 3.2677 4.62264C3.2677 6.75635 4.99882 8.48747 7.13252 8.48747ZM10.5679 9.34632H9.08909C8.49326 9.62008 7.83034 9.77574 7.13252 9.77574C6.43471 9.77574 5.77447 9.62008 5.17595 9.34632H3.69712C1.7996 9.34632 0.261719 10.8842 0.261719 12.7817V13.2111C0.261719 13.9224 0.838759 14.4994 1.54999 14.4994H12.7151C13.4263 14.4994 14.0033 13.9224 14.0033 13.2111V12.7817C14.0033 10.8842 12.4654 9.34632 10.5679 9.34632Z"
                                            fill="#0F563D"
                                        />
                                    </svg>
                                    Login
                                </Link>
                                <span>/</span>
                                <Link to="/register">Register</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                    <div className="container-fluid">
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-5">
                                <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to="">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="" className="nav-link">
                                        All Porsche Cars
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/compare-cars">
                                        Compare Cars
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about-us">
                                        Abouts Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact-us">
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}
export default Navbar;
