import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import OTPInput, { ResendOTP } from "otp-input-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function OtpVerification() {
    const [otp, setOtp] = useState("")
    const navigate = useNavigate()
    const verificationToken = JSON.parse(localStorage.getItem('user'))

    const verifyemail = event => {
        event.preventDefault();
        if (otp.length < 6) {
            toast.error('OTP Required!');
        } else if (!verificationToken || !verificationToken.data) {
            toast.error('User data not found in localStorage.');
        } else {
            toast.loading('Verifying OTP');
            fetch(`https://api.index911.com/api/auth/email/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'client-access-token': 'HS512384256562345690',
                },
                body: JSON.stringify({
                    "otp": otp,
                    "first_name": verificationToken.data.first_name,
                    "email": verificationToken.data.email,
                    "password": verificationToken.data.password,
                    "last_name": verificationToken.data.last_name,
                    "token": verificationToken.data.token,
                }),
            }).then(res => res.json())
                .then(data => {
                    toast.dismiss()
                    if (data.status === "Ok") {
                        toast.success(data.message);

                        setTimeout(() => {
                            toast.dismiss();
                            navigate("/");
                        }, 2000);
                    } else {
                        toast.dismiss();
                        toast.error(data.message);
                    }
                })
                .catch(err => {
                    toast.dismiss();
                    toast.error(err.message);
                });
        }
    }
    return (
        <section className="py-xxl-10 pb-0 py-4" >
            <ToastContainer position='top-center' />
            <div className="container-fluid">
                <div className="align-items-center">
                    <div className="col-md-7 col-lg-7 mx-auto h-100 maindeskForm">
                        <div className="text-center my-4 py-4">
                            <div className="otp-sendform">
                                <form id="OtpformId" onSubmit={verifyemail}>
                                    <OTPInput className="justify-content-center"
                                        value={otp}
                                        onChange={setOtp}
                                        autoFocus
                                        OTPLength={6}
                                        otpType="number"
                                        disabled={false}
                                        inputClassName="m-2 text-center form-control otpcode-custom"
                                        secure={false}
                                    />
                                    <div className="mt-4 ">
                                        <button className="mb-2 more-details-btn text-capitalize" id="otpSend" type="submit">Continue</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default OtpVerification;