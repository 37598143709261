import React, { useEffect, useState } from 'react';
import Graph from "./Graph";
import "./modal.css";
import LoadingSpinner from '../Home/LoadingSpinner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { MetaTags } from 'react-meta-tags';
import { useLocation } from 'react-router-dom';

const Models = () => {
  window.scrollTo(0, 0);
  const [isLoading, setIsLoading] = useState(false);
  const [average, setAverage] = useState([]);
  const [eurValue, setEurValue] = useState(null);
  const location = useLocation();
  const currentUrl = location.pathname + location.search;


  const fetchAverage = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://api.index911.com/api/cars/average`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'client-access-token': 'HS512384256562345690',
        },
      });
      const data = await response.json();
      if (data.status === "Ok") {
        setAverage(data.data);
      } else {
        toast.dismiss();
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAverage();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/eur.json');
  //       const jsonData = await response.json();
  //       setEurValue(jsonData.eur);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/eur.json');
        const jsonData = await response.json();
        setEurValue(jsonData.eur);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const getConvertedPrice = (amount) => {
    if (amount && eurValue) {
      const convertedPrice = parseFloat(amount.average_price) / eurValue[amount.currency_name];
      return convertedPrice.toFixed(3);
    }
    return null;
  };

  return (
    <section className='py-lg-5 py-2 my-lg-3 modalcompare-cus px-md-1 px-2'>

      <div className='container'>
        <ToastContainer position='top-center' />
        <div className='row'>
          <div className='col-md-5'>
            <div className='mt-lg-5'>
              <h3 className='h3'>Avg. cost per country</h3>
              <div className='row'>
                <div className='col-md-12'>
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Country Name</th>
                          <th>Average Price</th>
                          <th>Converted Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {average.map((data) => (
                          <tr key={data.id}>
                            <td><b>{data.name}</b></td>
                            <td><b className="takenprice"> {data.currency_name}</b> &nbsp;{parseFloat(data.average_price).toLocaleString()}</td>
                            <td> <b className="porche-price-cus" style={{ fontWeight: "bold" }}></b>{getConvertedPrice(data)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-7 newgraph-m'>
            <h2 className='h3'>Search Porsche models</h2>
            <Graph />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Models;

