import React, { useEffect, useState } from 'react';
import './Compare.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Autocomplete, TextField } from '@mui/material';
import Porsche from "./Porsche";
import Plus from "../../assets/images/plus.png";
import { Button } from 'evergreen-ui';
import { useLocation } from 'react-router-dom';

const basePath = "https://api.index911.com/";

const Compare = () => {
    const [modelOptions, setModelOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [bothSelected, setBothSelected] = useState([false, false, false, false]);
    const [selectedSlugDetails, setSelectedSlugDetails] = useState([{}, {}, {}, {}]);
    const [cardFirst, setCardFirst] = useState([false, false, false, false]);
    const [option, setOption] = useState(null);


    const initialCardState = {
        modelName: '',
        country: '',
        productList: [],
        selectedModelId: '',
        selectedCountryId: ''
    };

    const location = useLocation();
    const currentUrl = location.pathname + location.search;


    useEffect(() => {
        if (location.state && location.state.carsToCompare) {
            const selectedOption = location.state.carsToCompare[0];
            const multipliedPrice = selectedOption.currency_converter_price * selectedOption.price;
            setOption({ ...selectedOption, multipliedPrice });
            console.log(selectedOption, "1111111111")
        } else {
            setOption(null);
        }
    }, [location]);
    const [cards, setCards] = useState([initialCardState, initialCardState, initialCardState, initialCardState]);

    useEffect(() => {
        searchModels();
        searchCountry();
    }, []);

    useEffect(() => {
        bothSelected.forEach((selected, index) => {
            if (selected) {
                searchData2(index);
            }
        });
    }, [bothSelected]);

    const searchModels = () => {
        setIsLoading(true);
        fetch(`https://api.index911.com/api/cars/model`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === "Ok") {
                    const filteredOptions = data.data.filter((option) => option.model_name.includes("911"));
                    const textOption = { model_id: "0" }; // Text option to prepend
                    const modifiedOptions = [textOption, ...filteredOptions];
                    setModelOptions(modifiedOptions);
                    setIsLoading(false);
                } else {
                    // Handle error
                }
            });
    };

    const searchCountry = () => {
        setIsLoading(true);
        fetch(`https://api.index911.com/api/cars/getAllCountries`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === "Ok") {
                    setCountryOptions(data.data);
                    setIsLoading(false);
                } else {
                    // Handle error
                }
            });
    };

    const handleModelNameChange = (index, event, newValue) => {
        const updatedCards = [...cards];
        updatedCards[index] = {
            ...updatedCards[index],
            modelName: newValue,
            selectedModelId: newValue?.id || ''
        };
        setCards(updatedCards);
        setBothSelected((prevBothSelected) => {
            const newBothSelected = [...prevBothSelected];
            newBothSelected[index] = !!(newValue && updatedCards[index].selectedCountryId);
            return newBothSelected;
        });
    };

    const handleCountryChange = (index, event, newValue) => {
        const updatedCards = [...cards];
        updatedCards[index] = {
            ...updatedCards[index],
            country: newValue,
            selectedCountryId: newValue?.id || ''
        };
        setCards(updatedCards);
        setBothSelected((prevBothSelected) => {
            const newBothSelected = [...prevBothSelected];
            newBothSelected[index] = !!(updatedCards[index].selectedModelId && newValue);
            return newBothSelected;
        });
    };

    const handleProductListChange = (index, event, newValue) => {
        const updatedCards = [...cards];
        updatedCards[index] = {
            ...updatedCards[index],
            productList: newValue
        };
        setCards(updatedCards);
        if (newValue) {
            fetchSlugDetails1(index, newValue.slug);
        }
    };

    const fetchSlugDetails1 = (index, slug) => {
        fetch(`https://api.index911.com/api/cars/car-details/${slug}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'Ok') {
                    setSelectedSlugDetails((prevDetails) => {
                        const newDetails = [...prevDetails];
                        newDetails[index] = data.data;
                        return newDetails;
                    });
                    const currencyConverterPrice = parseFloat(data.data.car.currency_converter_price);
                    const price = parseFloat(data.data.car.price);
                    if (!isNaN(currencyConverterPrice) && !isNaN(price)) {
                        const multipliedPrice = currencyConverterPrice * price;
                        setSelectedSlugDetails((prevDetails) => {
                            const newDetails = [...prevDetails];
                            newDetails[index] = {
                                ...newDetails[index],
                                multipliedPrice: multipliedPrice,
                            };
                            return newDetails;
                        });
                    }
                    setCardFirst((prevCardFirst) => {
                        const newCardFirst = [...prevCardFirst];
                        newCardFirst[index] = true;
                        return newCardFirst;
                    });
                } else {
                    console.error('Error:', data.message);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const searchData2 = (index) => {
        if (!cards[index].selectedModelId || !cards[index].selectedCountryId) {
            return;
        }
        setIsLoading(true);
        fetch(`https://api.index911.com/api/cars/search/compare`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
            body: JSON.stringify({
                model_id: cards[index].selectedModelId,
                country_id: cards[index].selectedCountryId,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                setIsLoading(false);
                if (data.status === 'Ok') {
                    setCards((prevCards) => {
                        const newCards = [...prevCards];
                        newCards[index] = {
                            ...newCards[index],
                            productList: data.data.cars
                        };
                        return newCards;
                    });
                } else {
                    // Handle error
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };


    const renderCard = (index) => {
        return (
            <Col md={3} key={index}>
                <div className="card">
                    {index === 0 && option ? (
                        <div>
                            <div className="card-body">
                                {option.image_path && option.image_path.length > 0 && (
                                    <img className='card-img' src={`${basePath}${option.image_path[0]}`} alt="Car" />
                                )}

                                <div className='pt-2'>
                                    <p>Model: {option.model_name}</p>
                                    <p>Mileage: {option.mileage}</p>
                                    <p>Transmission: {option.transmission}</p>
                                    <p>Price: {option.currency} {option.price}</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {!cardFirst[index] && (
                                <div>
                                    <div className="addCricle">
                                        <div className='text-center mt-4'>
                                            <img src={Plus} alt='plus' />
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <Autocomplete
                                            value={cards[index].modelName}
                                            onChange={(event, newValue) => handleModelNameChange(index, event, newValue)}
                                            options={modelOptions}
                                            loading={isLoading}
                                            getOptionLabel={(option) => option.model_name || ''}
                                            renderInput={(params) => <TextField {...params} label="Model Name" />}
                                        />
                                        <Autocomplete
                                            value={cards[index].country}
                                            onChange={(event, newValue) => handleCountryChange(index, event, newValue)}
                                            options={countryOptions}
                                            loading={isLoading}
                                            getOptionLabel={(option) => option.name || ''}
                                            renderInput={(params) => <TextField {...params} label="Country" />}
                                        />
                                        <Autocomplete
                                            value={cards[index].productList}
                                            onChange={(event, newValue) => handleProductListChange(index, event, newValue)}
                                            options={cards[index].productList}
                                            getOptionLabel={(option) => option.slug || ''}
                                            renderInput={(params) => <TextField {...params} label="Product List" />}
                                        />
                                    </div>
                                </div>
                            )}
                            {cardFirst[index] && selectedSlugDetails[index].car && (
                                <div>
                                    <div className="card-body">
                                        <img className='card-img' src={`${basePath}${selectedSlugDetails[index].carImage[0]?.image_path}`} alt="Car" />
                                        <div className='pt-2'>
                                            <p>Model: {selectedSlugDetails[index].car.model_name}</p>
                                            <p>Mileage: {selectedSlugDetails[index].car.mileage}</p>
                                            <p>Transmission: {selectedSlugDetails[index].car.transmission}</p>
                                            <p>Price: {selectedSlugDetails[index].car.currency} {selectedSlugDetails[index].car.price}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Col>
        );
    };


    return (
        <div>
            <section className="compare-carsss">
                <Container>
                    <Row>
                        <div className='mx-5 mt-5 easy-way '>Confused? Easy way to<br /> compare cars</div>
                    </Row>
                </Container>
            </section>

            <section>
                <Container className='home-text'>
                    <h5>Compare Car</h5>
                    <Row className='mt-3'>
                        {Array.from({ length: 4 }).map((_, index) => renderCard(index))}
                        <Col md={12}>
                            <div className='searh-btns my-2 pb-md-4 pb-2 pt-0 text-center'>
                                <Button className='Search-btn text-capitalize mt-4 comparing-car-btn'
                                    variant="contained" >
                                    Compare
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <div className='home-text'>
                    <Porsche />
                </div>
            </section>
        </div>
    );
};

export default Compare;
