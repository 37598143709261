import React, { useEffect, useState } from 'react';
import "./ContactUs.css";
import { Row, Col, Card, Container } from 'react-bootstrap';
import { Textarea } from 'evergreen-ui';
import { Button, TextField } from '@mui/material';
import { isEmpty, isUndefined } from 'lodash';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
// import { MetaTags } from 'react-meta-tags';

const ContactUs = () => {
    window.scrollTo(0, 0)
    const [name, setName] = useState();
    const [nameError, setNameError] = useState();
    const [email, setEmail] = useState();
    const [emailError, setEmailError] = useState();
    const [phone, setphone] = useState();
    const [phoneError, setphoneError] = useState();
    const [message, setMessage] = useState();
    const [messageError, setMessageError] = useState();
    const [country, setCountry] = useState();
    const [countryError, setCountryError] = useState();
    let navigate = useNavigate();
    const location = useLocation();
    const currentUrl = location.pathname + location.search;

    const validateLogin = () => {
        if (!isUndefined(email)) {
            if (isEmpty(email)) {
                setEmailError("Email is a required field")
            }
            else if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                setEmailError("Please enter a valid email");
            } else {
                setEmailError("")
            }
        }
        if (!isUndefined(name)) {
            if (isEmpty(name)) {
                setNameError("Name is a required field")
            }
            else if (!(/^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{2,}$/.test(name))) {
                setNameError("Please enter valid name")
            } else {
                setNameError("")
            }
        }
        if (!isUndefined(phone)) {
            if (isEmpty(phone)) {
                setphoneError("Number is a required field")
            }
            else if (!(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone))) {
                setphoneError("Please enter valid number")
            } else {
                setphoneError("")
            }
        }
        if (!isUndefined(country)) {
            if (isEmpty(country)) {
                setCountryError("Country is a required field")
            }
            else {
                setCountryError("")
            }
        }
        if (!isUndefined(message)) {
            if (isEmpty(message)) {
                setMessageError("Messagae is a required field")
            }
            else {
                setMessageError("")
            }
        }
    }
    useEffect(() => {
        validateLogin();
    }, [email, phone, name, country, message]);

    const contactUs = event => {
        event.preventDefault()
        fetch(`https://api.index911.com/api/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
            body: JSON.stringify({ name: name, email: email, message: message, phone: phone, country: country })
        }).then(res => res.json())
            .then(data => {
                if (data.status === "Ok") {
                    toast.success(data.message)
                    // console.log(data, "CONTACTUS")
                    setTimeout(() => {
                        toast.dismiss()
                        navigate('/')
                    }, 1000)
                } else {
                    toast.dismiss();
                    toast.error(data.message)
                }
            })
            .catch(err => {
                toast.dismiss()
                toast.error(err.message)
            })
    }

    return (
        <div>
            <section className='contact-us-img'>
                <ToastContainer position='top-center' />
                {/* <MetaTags>
                    <link rel="canonical" href={`https://www.index911.com${currentUrl}`} />
    </MetaTags> */}
                <Container>
                    <Row>
                        <Col className='my-5 py-md-4'>
                            <div className='conatct-us text-center text-md-start'>
                                <h2 className='mb-md-5 mb-4'>Conatct Us</h2>
                                <h3 >Address</h3>
                                <p className='address'>Satravagen 6, 13338 Saltsjöbaden, Sweden
                                </p>

                            </div>
                            { /*   <div className='conatct-us text-center text-md-start'>
                                   <h2 className='mb-md-5 mb-4'>Conatct Us</h2>
                                <h3 >Call Us</h3>
                                <p className='mb-0'>1800 123 1231</p>
                                <p className='mb-4'><span >(Mon to Sat 9:30 A:M to 5 P:M)</span></p>
                                <h3 >Email</h3>
                                <p className='info-email'>info@index911@gmail.com</p> <br />
                                <h3 >Connect With Us</h3>
                            </div>*/}
                            { /*     <div className='icons text-center text-md-start'>
                                <FaFacebookSquare /> &nbsp;
                                <FaInstagram /> &nbsp;
                                <FaTwitter />&nbsp;
                                <FaLinkedinIn />
    </div> */}
                        </Col>
                        <Col md={7} lg={7}>
                            <Card className='contact-form my-md-5 rounded-0 p-md-3 p-1 mb-4'>
                                <Card.Body>
                                    <h1> <span> Let us Contact you</span> </h1>  <br />
                                    <form className='form' >
                                        <Row>
                                            <Col md={6}>
                                                <input className='px-3' type="text" required placeholder='Enter your Name' onChange={(e) => setName(e.target.value)} value={name} /> &nbsp;
                                                <span className="text-danger">{nameError}</span>
                                            </Col>
                                            <Col md={6}>
                                                <input className='px-3' type="text" required placeholder='Enter Email' onChange={(e) => setEmail(e.target.value)} value={email} />  &nbsp;
                                                <span className="text-danger">{emailError}</span> <br />
                                            </Col>
                                            <Col md={6}>
                                                <input className='px-3' type="number" required placeholder='Enter your phone' onChange={(e) => setphone(e.target.value)} value={phone} /> &nbsp;
                                                <span className="text-danger">{phoneError}</span>
                                            </Col>
                                            <Col md={6}>
                                                <TextField className='countary-field'
                                                    required
                                                    id="outlined-required"
                                                    label="Country"
                                                    onChange={(e) => setCountry(e.target.value)} value={country}
                                                    style={{ height: "50px", width: "100%" }}
                                                />
                                                <span className="text-danger">{countryError}</span> <br /><br />
                                            </Col>
                                        </Row>
                                    </form>
                                    <Textarea className='text-area-cus rounded-0'
                                        id="outlined-required"
                                        required
                                        onChange={(e) => setMessage(e.target.value)}
                                        value={message}
                                        placeholder="Enter your Message"
                                    />
                                    <span className="text-danger">{messageError}</span><br /><br />
                                    <Button className='search-btn rounded-0 mt-2 text-white text-capitalize' variant="contained"
                                        onClick={contactUs}
                                        disabled={
                                            isUndefined(name) || isUndefined(phone) || isUndefined(email)
                                            || isUndefined(country) || isUndefined(message) ||
                                            !(isEmpty(nameError)) && (isEmpty(phoneError)) && (isEmpty(emailError)) && (isEmpty(phoneError)) && (isEmpty(countryError))
                                        } >Submit</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section>
                <Container>
                    <Row>
                        <div>
                            <Col md={12}>
                                <h1>Business with CarDekho</h1>
                                <div className='d-flex'>
                                    <p>E-MAIL <br /> <span>support@index911.com</span></p>
                                    <p>USER CAR Business<br /> <span className='px-3'>Become Partner Dealer</span></p>
                                </div>
                            </Col>

                            <hr />
                            <Row>
                                <h3>Our Offices</h3>
                                <Col md={4}>
                                    <div style={{ borderRight: "2px solid" }}>
                                        <p>Jaipur Office</p>
                                        <p>Gir Software Pvt. Ltd, <br /> JTM, Modal Town, Near Jagatpur flyover, <br />Jaipur-30217</p>
                                    </div>
                                </Col>

                                <Col md={4}>
                                    <div style={{ borderRight: "2px solid" }}>
                                        <p>Jaipur Office</p>
                                        <p>Gir Software Pvt. Ltd, <br /> JTM, Modal Town, Near Jagatpur flyover, <br />Jaipur-30217</p>
                                    </div>
                                </Col>

                                <Col md={4}>
                                    <div style={{ borderRight: "2px solid" }}>
                                        <p>Jaipur Office</p>
                                        <p>Gir Software Pvt. Ltd, <br /> JTM, Modal Town, Near Jagatpur flyover, <br />Jaipur-30217</p>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </Row>
                </Container>
            </section>

        </div>
    )
}
export default ContactUs;