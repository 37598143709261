import React, { useEffect, useState } from "react";
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

const Graph = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.index911.com/api/cars/average", {
          headers: {
            'Content-Type': 'application/json',
            'client-access-token': 'HS512384256562345690',
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        const convertedData = result.data.map(item => {
          const convertedPrice = item.average_price * item.currency_converter_price;
          return { ...item, converted_price: convertedPrice };
        });
        setData(convertedData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <ScatterChart
      width={700}
      height={400}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid />
      <XAxis dataKey="iso_code" />
      <YAxis dataKey="converted_price" />
      <Tooltip cursor={{ strokeDasharray: "3 3" }} />
      <Scatter name="A scatter" data={data} fill="#8884d8" />
    </ScatterChart>
  );
};

export default Graph;
