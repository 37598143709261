import React from 'react';
import FooterImg from "../../assets/images/loggo.png";
// import Location from "../../assets/images/location.png";
// import Phone from "../../assets/images/phone.png";
// import Email from "../../assets/images/email.png";
import "./Footer.css";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <section className='footer mt-4'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="mt-5 text-center">
                                <img src={FooterImg} alt='footer' />
                            </div>
                        </Col>


                        <p className='text-center mt-4'> <b>About Index 911</b></p>
                        <div className='d-flex justify-content-center address'>
                            <p className='help'><Link to="/privacy-policy">Privacy policy</Link> </p>
                            <p className='help'> <Link to="/contact-us"> Contact Us</Link> </p>
                            <p className='help'> <Link to="/about-us">  About Us</Link></p>
                        </div>
                        <p className='text-center mt-2'><b>Usefull Links</b></p>
                        <div className='d-flex justify-content-center address mb-4'>
                            <p className='help'><Link to="/"> Home</Link></p>
                            <p className='help'>All Porsche Cars</p>
                            <p className='help'> <Link to="/compare-cars">Compare Cars</Link></p>
                        </div>
                    </Row>
                </Container>
            </section>
            <section style={{ background: "#E5E5E5" }}>
                <Container>
                    <Row>
                        <p className='pt-3 copyright text-center mt-3'>   2023 copyright index 911 all rights reserved </p>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Footer;
