import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row, Container, Card } from 'react-bootstrap';
import LoadingSpinner from './LoadingSpinner';
import "./Homeblog.css";

const basePath = "http://api.index911.com/";

const HomeBlog = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [blogs, setBlogs] = useState([]);
    let navigate = useNavigate();

    const fetchBlog = () => {
        setIsLoading(true);
        fetch(`https://api.index911.com/api/posts/latestblog/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setIsLoading(false);
                if (data.status === "Ok") {
                    setBlogs(data.data.slice(0, 4));
                } else {
                    toast.dismiss();
                    toast.error(data.message);
                }
            });
    };

    useEffect(() => {
        fetchBlog();
    }, []);

    const handleReadMore = (slug) => {
        navigate(`/blogs-details/${slug}`);
    };

    function formatDate(dateString) {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    }

    function stripHtmlTags(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    return (
        <section className='blogs '>
            <Container>
                <Row>
                    <Col>
                        <h3 className='news'>Need help to choose your car?</h3>
                    </Col>
                </Row>
                <Row>
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        blogs.slice(0, 4).map((blog) => (
                            <Col md={6} className='mb-4 mt-4 d-flex' key={blog.slug}>
                                <Col md={4}>
                                    <div className='imgss'>
                                        <img className='w-100 h-100' src={`${basePath}${blog.image}`} />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className='ml-3'>
                                        <h6 className='mt-2 blog_name blog-title'><b>{blog.name}</b></h6>
                                        <p className='blog_name desciption'>{stripHtmlTags(blog.description).substring(0, 150)}</p>
                                        <div className='React read-more-blog d-flex justify-content-between'>
                                            <p className='blog_name date-format'>{formatDate(blog.created_at)}</p>
                                            <p className="blog_name read-moree" onClick={() => handleReadMore(blog.slug)}>Read More</p>
                                        </div>
                                    </div>
                                </Col>




                            </Col>
                        ))
                    )}
                    <div className='all-blogs mb-4 mt-2'>
                        <Link to="/" className='view-allcars mb-5'> View All Blogs</Link>
                    </div>
                </Row>


            </Container>
        </section>
    );
};

export default HomeBlog;
