import React, { useState, useEffect } from 'react';
import { Col, Container, Row, } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';

// import { MetaTags } from 'react-meta-tags';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../Home/LoadingSpinner';

const Privacy = () => {
    window.scrollTo(0, 0)
    const [isLoading, setIsLoading] = useState(false);
    const [about, setAbout] = useState({ slug: "", title: "" });
    const location = useLocation();
    const currentUrl = location.pathname + location.search;

    const fetchData = () => {
        setIsLoading(true);
        fetch(`https://api.index911.com/api/pages/?pslug=privacy-policy`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'client-access-token': 'HS512384256562345690',
                },
            })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === "Ok") {
                    setAbout(data.data);
                    setIsLoading(false);
                }
                else {
                    toast.dismiss();
                    toast.error(data.message)
                }
            })
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        isLoading ? <LoadingSpinner /> :
            <section className='my-5 about-us'>
                {/* <MetaTags>
                    <link rel="canonical" href={`https://www.index911.com${currentUrl}`} />
    </MetaTags> */}
                <ToastContainer position='top-center' />
                <Container className='blog'>
                    <Row>
                        <Col md={12} className='mb-4' >
                            <h1 className='mb-4' style={{ textAlign: "center" }}> Privacy Policy</h1>
                            <div dangerouslySetInnerHTML={{ __html: about?.description }} />
                            <div dangerouslySetInnerHTML={{ __html: about?.description }} />
                        </Col>
                    </Row>
                </Container>
            </section>
    )
}
export default Privacy