import React, { useEffect, useState } from 'react';
import "./Login.css";
import Button from '@mui/material/Button';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { isEmpty, isUndefined } from "lodash";
import { useNavigate, Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    window.scrollTo(0, 0)
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordError, setPasswordError] = useState();
    const [emailError, setEmailError] = useState("");
    let navigate = useNavigate();

    const validateLogin = () => {
        if (!isUndefined(email)) {
            if (isEmpty(email)) {
                setEmailError("Email is a required field")
            }
            else if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                setEmailError("Please enter a valid email");
            } else {
                setEmailError("")
            }
        }
        if (!isUndefined(password)) {
            if (isEmpty(password)) {
                setPasswordError("Password is a required field")
            }
            else if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/.test(password))) {
                setPasswordError("Please enter a valid password")
            }
            else {
                setPasswordError("")
            }
        }
    }
    useEffect(() => {
        validateLogin();
    }, [email, password]);

    const login = event => {
        event.preventDefault()
        fetch(`https://api.index911.com/api/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
            body: JSON.stringify({ email: email, password: password })
        }).then((res) => res.json())
            .then((data) => {
                toast.dismiss()
                if (data.status === "Ok") {
                    toast.success(data.message)
                    // localStorage.setItem("username", data.data);
                    localStorage.setItem("user", JSON.stringify(data));
                    setTimeout(() => {
                        toast.dismiss()
                        navigate("/");
                    }, 2000)
                }
                else {
                    // console.log("fkdf")
                    toast.dismiss();
                    toast.error(data.message)
                }
            })
    }

    return (
        <div>
            <section className='login-img'>
                <ToastContainer position='top-center' />
                <Container>
                    <Row>
                        <Col></Col>
                        <Col md={6} lg={5} >
                            <Card className='card-login p-md-4 p-2 my-5' >
                                <Card.Body>
                                    <form className='form' >
                                        <h1 className='mb-5'>Login</h1>
                                        <input className='px-3' type="text" placeholder='Your Email' onChange={(e) => setEmail(e.target.value)} value={email} />
                                        <span className="text-danger" style={{ textAlign: "center" }}>{emailError}</span> <br />
                                        <input className='px-3 mt-4' type="password" placeholder='Password' onChange={(e) => setPassword(e.target.value)} value={password} /> &nbsp;
                                        <span className="text-danger">{passwordError}</span>
                                        <div className='check-box justify-content-between  mt-3'>
                                            <div>
                                                <input type="checkbox" className="form-check-input d-inline-block me-2" id="exampleCheck1" style={{
                                                    marginTop: "4px",
                                                    width: "15px",
                                                    height: "15px"
                                                }} />
                                                <label className="form-check-label" for="exampleCheck1"  >Remember me</label>
                                            </div>
                                            <p > <Link to="/forgot-password" className='forgetpwd'>Forget Password?</Link></p>
                                        </div>
                                        <Button className='submit-btn rounded-0 text-capitalize mt-0 mb-0' variant="contained"
                                            onClick={login} disabled={
                                                isUndefined(email) || isUndefined(password) || !(isEmpty(emailError) && isEmpty(passwordError))
                                            }>Login</Button>
                                        <div className='sing-up mt-3 justify-content-between' >
                                            <p>Don’t Have an account?</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <h6 >  <Link to="/register">Sign up</Link>  </h6>
                                        </div>
                                    </form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
export default Login;