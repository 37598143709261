import React, { useEffect, useState } from 'react';
import "./TrustedCar.css";
import { Card, Col, Container, Row } from 'react-bootstrap';
import LoadingSpinner from './LoadingSpinner';
import { Link } from 'react-router-dom';

const basePath = 'http://api.index911.com/';

const TrustedCar = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = 'https://api.index911.com/api/cars/popular-searches';
                const headers = {
                    'Content-Type': 'application/json',
                    'client-access-token': 'HS512384256562345690',
                };
                const response = await fetch(url, { headers });
                const responseData = await response.json();
                if (responseData.status === 'Ok') {
                    setData(responseData.data.cars.slice(0, 15));
                } else {
                    console.error(responseData.message);
                }
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return (
        <section className='trust-car mt-3'>
            <Container>
                <Row>
                    {isLoading ? (
                        <><LoadingSpinner /></>
                    ) : (
                        <>
                            {data.slice(0, 3).map((item, index) => (
                                <Col key={index} xs={12} md={4} className='mt-2'>
                                    <Card className="card-height cards-imgaes mb-3" style={{ height: "100%", }}>
                                        <div className="d-flex justify-content-between mb-2">
                                            <Col md={6}>
                                                <div className="image-container position-relative">
                                                    <img
                                                        className='w-100 h-100 trust-product-img  trust-img1'
                                                        src={`${basePath}${item.image_path[0]}`}
                                                        alt={`car-Img-${index}`} />
                                                    <div className="overlay"></div>
                                                </div>
                                            </Col>
                                            <span className="vs-text">Vs</span>
                                            <Col md={6}>
                                                <div className="image-container position-relative">
                                                    <img
                                                        className='w-100 h-100 trust-product-img trust-img2'
                                                        src={`${basePath}${data[index + 4].image_path[0]}`}
                                                        alt={`car-Img-${index + 4}`} />
                                                    <div className="overlay"></div>
                                                </div>
                                            </Col>
                                        </div>
                                        <Card.Body style={{ height: "calc(100% - 100px)" }}>
                                            <div className='card-data1'>
                                                <Row>
                                                    <Col md={6}>
                                                        <div>
                                                            <p className='trust-title'><b>{item.title}</b></p>
                                                            <p className='trust-title'> Price:<br /> <b>{item.currency} {item.price}</b></p>
                                                            <p className='trust-title'>Mileage:<br /> <b>{item.mileage}</b></p>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div>
                                                            <p className='trust-title'><b>{data[index + 4].title}</b></p>
                                                            <p className='trust-title'>Price:<br /><b>{data[index + 4].currency} {data[index + 4].price}</b> </p>
                                                            <p className='trust-title'>Mileage: <br /><b>{data[index + 4].mileage}</b></p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='text-center comparing-trust-model d-flex postion-relative'>
                                                <Row>
                                                    <Col md={6}>
                                                        <div style={{ padding: "0px 12px 4px 15px" }}>
                                                            {item.title}
                                                        </div>
                                                    </Col>
                                                    <span className='carsvs'> <b>VS</b></span>
                                                    <Col md={6}>
                                                        <div style={{ padding: '0px 10px 5px 18px' }}>
                                                            {data[index + 4].title}
                                                        </div>

                                                    </Col>
                                                </Row>
                                            </div>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </>
                    )}
                    <div className='all-blogs mb-4 mt-3'>
                        <Link to="/" className='view-allcars mb-5'> View All Cars Comparisons</Link>
                    </div>
                </Row>
            </Container>
        </section>
    );
}

export default TrustedCar;
