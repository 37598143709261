// import React, { useEffect, useState } from 'react';
// // import 'bootstrap/dist/css/bootstrap.min.css';
// import { Card, Row, Col, Button, Container } from 'react-bootstrap';
// import "./ProductDetails.css";
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';
// import LoadingSpinner from '../Home/LoadingSpinner';
// import Mill from "../../assets/images/Mill.png"
// import Fuel from "../../assets/images/fuel.png"
// import Power from "../../assets/images/power.png"
// import SearchedCar from '../Home/SearchedCar';

import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row, Button } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { toast } from 'react-toastify';
import LoadingSpinner from '../Home/LoadingSpinner';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Mill from "../../assets/images/Mill.png"
import Fuel from "../../assets/images/fuel.png"
import Power from "../../assets/images/power.png"
import SearchedCar from '../Home/SearchedCar';


const basePath = "http://api.index911.com/";

const ProductDetails = () => {
    window.scrollTo(0, 0)
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const { slug } = useParams();
    const [products, setProducts] = useState([{}]);
    const [details, setDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [images1, setImages1] = useState([]);;
    const [activeProductSlug, setActiveProductSlug] = useState('');
    const navigate = useNavigate();
    const [url, setUrl] = useState('');
    const [domain, setDomain] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const location = useLocation();
    const currentUrl = location.pathname + location.search;

    const fetchData = () => {
        setIsLoading(true);
        fetch(`https://api.index911.com/api/cars/car-details/${slug}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === "Ok") {
                    const carImage = data.data.carImage;
                    const modifiedCarImage = carImage.map(image => ({
                        ...image,
                        multipliedPrice: image.currency_converter_price * image.price
                    }));
                    setProducts(modifiedCarImage);
                    setDetails({
                        ...data.data.car,
                        multipliedPrice: data.data.car.currency_converter_price * data.data.car.price
                    });
                    setIsLoading(false);
                } else {
                    toast.dismiss();
                    toast.error(data.message);
                }
            });
    }

    useEffect(() => {
        if (details && details.url) {
            setUrl(details.url);
            setDomain(new URL(details.url).hostname);
        }
        if (selectedProduct && selectedProduct.url) {
            setUrl(selectedProduct.url);
            setDomain(new URL(selectedProduct.url).hostname);
        }
    }, [details, selectedProduct]);
    useEffect(() => {
        if (url) {
            setDomain(new URL(url).hostname);
        }
    }, [url]);
    const handleLinkClick = () => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    useEffect(() => {
        fetchData()
    }, [])


    const fetchData1 = () => {
        setIsLoading(true);
        fetch(`https://api.index911.com/api/cars/getAllCars`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'Ok') {
                    const shuffledCars = shuffleArray(data.data.cars);
                    setImages1(shuffledCars);
                } else {
                    toast.dismiss();
                    toast.error(data.message);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    };

    const shuffleArray = (array) => {
        // Create a copy of the array
        const shuffledArray = [...array];
        // Perform Fisher-Yates shuffle algorithm
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };
    useEffect(() => {
        fetchData1();
    }, []);

    const handleCompareClick = (car) => {
        navigate('/compare-cars', { state: { carsToCompare: [car] } });
    };
    const handleMoreDetailsClick = (slug) => {
        setIsLoading(true);
        fetch(`https://api.index911.com/api/cars/car-details/${slug}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'Ok') {
                    const carImage = data.data.carImage;
                    const modifiedCarImage = carImage.map(image => ({
                        ...image,
                        multipliedPrice: image.currency_converter_price * image.price
                    }));
                    setProducts(modifiedCarImage);
                    setDetails({
                        ...data.data.car,
                        multipliedPrice: data.data.car.currency_converter_price * data.data.car.price
                    });
                    setIsLoading(false);
                } else {
                    toast.dismiss();
                    toast.error(data.message);
                }
            });
        console.log(`Clicked "More Details" for slug: ${slug}`);
    };

    return (
        <>
            <section>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Container>
                        {selectedProduct ? (
                            <>
                                <Row className='py-5'>


                                    {products.length === 1 ? (
                                        <Col md={6} className='product-slider'>
                                            <img src={`${basePath}${products[0].image_path}`} alt={`product-detail-single`} />
                                        </Col>
                                    ) : (
                                        <Col md={6} className='product-slider'>
                                            <div>
                                                <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                                                    {products.map((product, index) => (
                                                        <img key={index} src={`${basePath}${product.image_path}`} alt={`product-detail-slider1-${index}`} />
                                                    ))}
                                                </Slider>

                                                {products.length > 0 && (
                                                    <Slider
                                                        className='second-p-slider'
                                                        asNavFor={nav1}
                                                        ref={(slider2) => setNav2(slider2)}
                                                        slidesToShow={products.length}
                                                        swipeToSlide={true}
                                                        focusOnSelect={true}
                                                    >
                                                        {products.map((product, index) => (
                                                            <img key={index} className="botom-img" src={`${basePath}${product.image_path}`} alt={`product-detail-slider2-${index}`} />
                                                        ))}
                                                    </Slider>
                                                )}
                                            </div>
                                        </Col>
                                    )}



                                    <Col md={6}>

                                        <h3 className='border-bottom p-3'>{selectedProduct.title}</h3>
                                        {selectedProduct.model_name || selectedProduct.year || selectedProduct.color ? (
                                            <div className="d-flex align-items-center px-3 pt-3 justify-content-between">
                                                {selectedProduct.model_name && (
                                                    <p className="takenC text mb-0">
                                                        <b>Model &nbsp;</b><span className="selected-product"> {selectedProduct.model_name}</span>
                                                    </p>
                                                )}
                                                {selectedProduct.year && (
                                                    <p className="takenC text mb-0">
                                                        <b>Year &nbsp;</b><span className="selected-product"> {selectedProduct.year}</span>
                                                    </p>
                                                )}
                                                {selectedProduct.color && (
                                                    <p className="takenC text mb-0">
                                                        <b>Color &nbsp;</b><span className="selected-product"> {selectedProduct.color}</span>
                                                    </p>
                                                )}
                                            </div>
                                        ) : null}


                                        {selectedProduct.make || selectedProduct.body_type || selectedProduct.transmission ? (
                                            <div className="d-flex align-items-center px-3 pt-3 justify-content-between">
                                                {selectedProduct.model_name && (
                                                    <p className="takenC text mb-0">
                                                        <b>Model &nbsp;</b><span className="selected-product">   {selectedProduct.make}</span>
                                                    </p>
                                                )}
                                                {selectedProduct.body_type && (
                                                    <p className="takenC text mb-0">
                                                        <b>Body Type &nbsp;</b><span className="selected-product"> {selectedProduct.body_type}</span>
                                                    </p>
                                                )}
                                                {selectedProduct.transmission && (
                                                    <p className="takenC text mb-0">
                                                        <b>Transmission &nbsp;</b><span className="selected-product"> {selectedProduct.transmission}</span>
                                                    </p>
                                                )}
                                            </div>
                                        ) : null}


                                        {selectedProduct.power || selectedProduct.mileage ? (
                                            <div className="d-flex align-items-center px-3 pt-3 justify-content-between">

                                                {selectedProduct.mileage && (
                                                    <div className='mileage'>
                                                        <p className="takenC text mb-0">
                                                            <b><span><img src={Mill} alt={Mill} />
                                                            </span> &nbsp;Mileage &nbsp;</b> <span className="selected-product">  {details.selectedProduct}</span>
                                                        </p>
                                                    </div>

                                                )}

                                                {selectedProduct.power && (
                                                    <div className='mileage'>
                                                        <p className="takenC text mb-0">
                                                            <b><span><img src={Power} alt={Power} />
                                                            </span> &nbsp;Power &nbsp;</b> <span className="selected-product">  {selectedProduct.power}</span>
                                                        </p>
                                                    </div>
                                                )}

                                            </div>
                                        ) : null}


                                        {selectedProduct.fuel_type ? (
                                            <div className="d-flex align-items-center px-3 pt-3 justify-content-between">
                                                <p className="takenC text mb-0">
                                                    <div className='mileage'>
                                                        <b><span><img src={Fuel} alt={Fuel} />
                                                        </span> &nbsp;Fuel Type &nbsp;</b> <span className="selected-product"> {selectedProduct.fuel_type}</span>
                                                    </div>
                                                </p>
                                            </div>
                                        ) : null}



                                        <h4 className='border-top px-3 pt-2 h4 mb-0 mt-3'>Sold By</h4>
                                        <div className='px-3 '>
                                            <p className='mb-0 taken text-uppercase'> <span className="selected-product">{details.address}</span> </p>
                                        </div>
                                        <div className='px-3 border-bottom pb-3 mb-2'>
                                            <p className='mb-0 taken text-uppercase'>
                                                <span><i className="fas fa-map-marker-alt"> </i>  </span>&nbsp;
                                                <span><b>ystad</b></span>
                                                {/* &nbsp; 120 vehicles in stick */}
                                            </p>
                                        </div>
                                        <div className='py-3 border-top p-3 mt-3'>

                                            <div className="d-flex align-items-center px-3 pt-3  justify-content-between">
                                                <p className="takenC text mb-0">
                                                    <b>Price &nbsp;</b> <b>{selectedProduct.currency_name}</b>     {selectedProduct.price}
                                                </p>
                                                {selectedProduct.multipliedPrice !== undefined && (
                                                    <div className='details-price'>
                                                        <p className="takenC text mb-0"><b>Price</b> &nbsp; <b>€ </b><span className="selected-product">{selectedProduct.multipliedPrice.toFixed(3)} </span></p>
                                                    </div>
                                                )}
                                            </div>


                                            <div className='col-md-12'>
                                                <h4 className='border-top px-3 pt-2 h4 mb-0 mt-3 selected-product'>Sold By <span className='mx-2 selected-product'> {selectedProduct.address}</span> <span className='mx-2'>YSTAD</span></h4>
                                            </div>

                                            {url && (
                                                <a href={url} className='listingbtn' target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                                                    <Button className='btn takemebtn mb-2 mt-3 rounded-0 d-block w-75 mx-auto border-0'>
                                                        Take me to listing
                                                    </Button>
                                                </a>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row className='py-5'>


                                    {products.length === 1 ? (
                                        <Col md={6} className='product-slider'>
                                            <img src={`${basePath}${products[0].image_path}`} alt={`product-detail-single`} />
                                        </Col>
                                    ) : (
                                        <Col md={6} className='product-slider'>
                                            <div>
                                                <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                                                    {products.map((product, index) => (
                                                        <img key={index} src={`${basePath}${product.image_path}`} alt={`product-detail-slider1-${index}`} />
                                                    ))}
                                                </Slider>

                                                {products.length > 0 && (
                                                    <Slider
                                                        className='second-p-slider'
                                                        asNavFor={nav1}
                                                        ref={(slider2) => setNav2(slider2)}
                                                        slidesToShow={products.length}
                                                        swipeToSlide={true}
                                                        focusOnSelect={true}
                                                    >
                                                        {products.map((product, index) => (
                                                            <img key={index} className="botom-img" src={`${basePath}${product.image_path}`} alt={`product-detail-slider2-${index}`} />
                                                        ))}
                                                    </Slider>
                                                )}
                                            </div>
                                        </Col>
                                    )}


                                    <Col md={6}>
                                        <h3 className='border-bottom p-3'>{details.title}</h3>
                                        {details.model_name || details.year || details.color ? (
                                            <div className="d-flex align-items-center px-3 pt-3 justify-content-between">
                                                {details.model_name && (
                                                    <p className="takenC text mb-0">
                                                        <b>Model &nbsp;</b><span className="selected-product"> {details.model_name}
                                                        </span>
                                                    </p>
                                                )}
                                                {details.year && (
                                                    <p className="takenC text mb-0">
                                                        <b>Year &nbsp;</b><span className="selected-product"> {details.year}</span>
                                                    </p>
                                                )}
                                                {details.color && (
                                                    <p className="takenC text mb-0">
                                                        <b>Color &nbsp;</b> <span className="selected-product">{details.color}</span>
                                                    </p>
                                                )}
                                            </div>
                                        ) : null}

                                        {details.make || details.body_type || details.transmission ? (
                                            <div className="d-flex align-items-center px-3 pt-3 justify-content-between">
                                                {details.model_name && (
                                                    <p className="takenC text mb-0">
                                                        <b>Model &nbsp;</b><span className="selected-product"> {details.make} </span>
                                                    </p>
                                                )}
                                                {details.body_type && (
                                                    <p className="takenC text mb-0">
                                                        <b>Body Type &nbsp;</b> <span className="selected-product">{details.body_type}
                                                        </span>
                                                    </p>
                                                )}
                                                {details.transmission && (
                                                    <p className="takenC text mb-0">
                                                        <b>Transmission &nbsp;</b><span className="selected-product"> {details.transmission} </span>
                                                    </p>
                                                )}
                                            </div>
                                        ) : null}

                                        {details.power || details.mileage ? (
                                            <div className="d-flex align-items-center px-3 pt-3 justify-content-between">
                                                {details.mileage && (
                                                    <div className='mileage'>
                                                        <p className="takenC text mb-0">
                                                            <b><span><img src={Mill} alt={Mill} />
                                                            </span> &nbsp;Mileage &nbsp;</b> <span className="selected-product">{details.mileage}</span>
                                                        </p>
                                                    </div>
                                                )}
                                                {details.power && (
                                                    <div className='mileage'>
                                                        <p className="takenC text mb-0">
                                                            <b><span><img src={Power} alt={Power} />
                                                            </span> &nbsp;Power &nbsp;</b><span className="selected-product"> {details.power} </span>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}
                                        {details.fuel_type ? (
                                            <div className="d-flex align-items-center px-3 pt-3 justify-content-between">
                                                <p className="takenC text mb-0">
                                                    <div className='mileage'>
                                                        <b><span><img src={Fuel} alt={Fuel} />
                                                        </span>  &nbsp; Fuel Type &nbsp;</b> <span className="selected-product">{details.fuel_type} </span>
                                                    </div>
                                                </p>
                                            </div>
                                        ) : null}
                                        <div className='py-3 border-top p-3 mt-3'>
                                            <div className="d-flex align-items-center px-3 pt-3  justify-content-between">
                                                <p className="takenC text mb-0">
                                                    <b>Price &nbsp;</b> <b>{details.currency_name}</b>     <span className="selected-product">{details.price} </span>
                                                </p>
                                                {details.multipliedPrice !== undefined && (
                                                    <div className='details-price'>
                                                        <p className="takenC text mb-0"><b>Price</b> &nbsp; <b>€ </b><span className="selected-product">{details.multipliedPrice.toFixed(3)}</span></p>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='col-md-12'>
                                                <h4 className='border-top px-3 pt-2 h4 mb-0 mt-3'>Sold By <span className='mx-2 selected-product'>{details.address}</span> <span className='mx-2'>YSTAD</span></h4>
                                            </div>
                                            {url && (
                                                <a href={url} className='listingbtn' target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                                                    <Button className='btn takemebtn mb-2 mt-5 rounded-0 d-block w-75 mx-auto border-0'>
                                                        Take me to listing
                                                    </Button>
                                                </a>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Container>
                )}
            </section >

            <section>
                <Container>
                    <Row>
                        <SearchedCar />
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default ProductDetails;

