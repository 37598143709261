import React, { useState, useEffect } from "react";
import VectorMap, { Layer, Tooltip, Border, Font } from "devextreme-react/vector-map";
import * as mapsData from "devextreme/dist/js/vectormap-data/world.js";
import LoadingSpinner from './LoadingSpinner';

export default function HomeMap() {
    const [countries, setCountries] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch("https://api.index911.com/api/cars/getAllCountries", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'client-access-token': 'HS512384256562345690',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === "Ok") {
                    const countriesData = {};
                    data.data.forEach(country => {
                        countriesData[country.name] = country;
                    });
                    setCountries(countriesData);
                } else {
                    console.error('Error:', data.message);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    function customizeTooltip(arg) {
        const name = arg.attribute("name");
        const country = countries[name];
        if (country) {
            const formattedAveragePrice = parseFloat(country.average_price).toFixed(2);
            const priceWithCurrency = `${country.currency} ${formattedAveragePrice}`;
            return {
                text: `${name}\nAverage Price: ${priceWithCurrency}`,
                color: country.color,
                price: country.currency_converter_price
            };
        }
        return null;
    }




    function customizeLayer(elements) {
        elements.forEach(element => {
            const name = element.attribute("name");
            const country = countries[name];
            if (country) {
                element.applySettings({
                    color: country.color
                });
            }
        });
    }

    return (
        <div className="row">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <VectorMap
                    id="vector-map"
                    containerStyle={{
                        width: "100%",
                        height: "700px",
                        overflow: "hidden",
                    }}
                    zoomFactor={2}
                    maxZoomFactor={2}
                    panningEnabled={false}
                    bounds={[-20, 0, 0, 100]}
                >
                    <Layer
                        dataSource={mapsData.world}
                        customize={customizeLayer}
                    ></Layer>
                    <Tooltip enabled={true} customizeTooltip={customizeTooltip}>
                        <Border visible={true}></Border>
                        <Font color="#fff"></Font>
                    </Tooltip>
                </VectorMap>
            )}
        </div>
    );
}



